import EntityService from "@/services/EntityService";
import router from "@/router";

const auth = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    user: null,
    username: "",
    password: "",
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_PASSWORD(state, password) {
      state.password = password;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    login(context) {
      try {
        EntityService.users.verify(
          context.state.username,
          context.state.password
        ).then((json) => {
          context.dispatch("setAuthenticated", true);
          context.dispatch("setUser", json);
          router.push({ name: "printList" });
        });
      } catch (e) {
        console.log(e);
     }
    },
    logout(context) {
      context.commit("SET_AUTHENTICATED", false);
      context.commit("SET_USERNAME", '');
      context.commit("SET_PASSWORD", '');
      context.commit("SET_USERNAME", null);
    },
    setUsername(context, username) {
      context.commit("SET_USERNAME", username);
    },
    setPassword(context, password) {
      context.commit("SET_PASSWORD", password);
    },
    setAuthenticated(context, authenticated) {
      context.commit("SET_AUTHENTICATED", authenticated);
    },
    setUser(context, user) {
      context.commit("SET_USER", user);
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    userName: (state) => {
      return state.user.fullName;
    },
    entityUser: (state) => {
      return state.username + ":" + state.password;
    },
    locations: (state) => {
      if (!state.user) {
        return [];
      }
      return state.user.locations;
    },
  },
};

export default auth;
