import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/",
    name: "printList",
    menuTitle: i18n.t("printlist.title"),
    component: () => import("@/views/PrintList")
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// auth check
router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !store.getters["auth/isAuthenticated"])
    next({ name: "login" });
  else next();
});

export default router;
