import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { preset } from "@tt/vue-vuetify-preset-tt/preset";

Vue.use(Vuetify);
export default new Vuetify({
  preset,
  options: { customProperties: true },
  theme: { light: true }
});
