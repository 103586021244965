<template>
  <v-app>
    <v-app-bar app clipped-left flat height="64px" shrink-on-scroll>
      <v-row no-gutters>
        <v-col style="max-height: 64px">
          <v-btn
              width="64px"
              height="64px"
              text
              tile
              elevation="0"
              style="vertical-align: top"
          >
            <v-img
                alt="Logo"
                contain
                :src="logo"
                transition="scale-transition"
                width="64"
            />
          </v-btn>
          <v-tabs
              slider-size="3"
              light
              color="ttPrimary"
              slider-color="primary"
              background-color="white"
              style="display: inline-block; width: auto"
          >
            <v-tab @click="goToPortal" class="px-0" style="min-width: 80px">
              <v-icon color="black">mdi-view-grid-outline</v-icon>
            </v-tab>
            <v-tab
                v-for="(routerItem, index) in menuItems"
                :key="index"
                :to="routerItem.path.replaceAll(/:(.*?)\?/gm, '').replace(/\/\//g, '/')"
                light
                :ripple="false"
            >
              <template>{{ routerItem.menuTitle }}</template>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col v-if="isAuthenticated" cols="auto" class="usermenu" style="max-height: 80px">
          <div class="d-inline-flex" style="width: 320px; height: 64px">
            <v-divider vertical />
            <div class="ml-auto float-right align-center d-flex d-flex-grow">
              <template>
                <div
                    class="text-sm-body-2 d-none d-inline-flex ml-2 align-center flex-grow-1"
                >
                  {{ userName }}
                </div>

                <div class="align-center d-inline-flex mx-2 flex-grow-1">
                  <SubMenu
                      light
                      name="User menu"
                      :menu-items="userMenuItems"
                      @sub-menu-click="onMenuItemClick"
                  >
                    <template v-slot:mainItem>
                      <v-avatar color="backgroundPrimary" size="48">
                        <v-img src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&f=y" />
                      </v-avatar>
                      <v-icon color="ttPrimary" class="pl-2" small>mdi-menu-down</v-icon>
                    </template>
                  </SubMenu>
                </div>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import EnvProvider from "@tt/vue-environment/EnvProvider";
import logo from "@tt/vue-components/src/assets/logo.png";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  data: () => ({
    portalUrl: EnvProvider.value("PORTAL_URL"),
    logo,
    languages: [
      {
        locale: "en",
        name: "english",
        flag: "gb"
      },
      {
        locale: "nl",
        name: "dutch",
        flag: "nl"
      }
    ]
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "userName"]),
    menuItems() {
      if (!this.isAuthenticated) {
        return [];
      }

      return this.$router.options.routes
          .filter(item => {
            return item.menuTitle !== undefined;
          })
    },
    userMenuItems() {
      let defaultItems = [];

      // Add languages, they will be prepended before other items
      let subMenuLanguages = [];

      let itemCount = 0;
      this.languages.forEach(language => {
        itemCount++;
        subMenuLanguages.push({
          name: this.$t("core.language_switch." + language.name) + " ",
          flag: language.flag,
          value: language.locale,
          action: value => {
            this.updateLocale(value);
          }
        });

        if (itemCount < this.languages.length) {
          subMenuLanguages.push({ isDivider: true });
        }
      });

      let currentLanguage = this.languages.find(
          language => language.locale === this.$i18n.locale
      );
      defaultItems.push({
        name: this.$t("app.localechanger.menu_item") + " ",
        flag: currentLanguage ? currentLanguage.flag : null,
        menu: subMenuLanguages,
        prepend: true
      });

      // Add account control, they will be appended after other items
      defaultItems.push({ isDivider: true });
      if (this.isAuthenticated) {
        defaultItems.push({
          name: this.$t("app.account.menu.label.logout"),
          prepend: false,
          icon: "mdi-logout-variant",
          action: () => {
            this.logout();
          }
        });
      } else {
        defaultItems.push({
          name: this.$t("app.account.menu.label.login"),
          prepend: false,
          action: () => {
            this.login();
          }
        });
      }

      return defaultItems;
    }
  },
  methods: {
    ...mapActions('auth', { reset: "logout"}),
    onMenuItemClick(item) {
      if (item.action) {
        item.action(item.value);
      }
    },
    logout() {
      this.reset();
      this.$router.push({name: 'login'});
    },
    updateLocale(locale) {
      this.$root.$i18n.locale = locale;
      localStorage.locale = locale;
      window.location.href = this.$router.currentRoute.fullPath;
    },
    goToPortal() {
      window.location.href = this.portalUrl;
    }
  }
};
</script>
