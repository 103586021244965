import { tableState, tableMutations, tableActions } from "@tt/vue-components";
import DownloadService from "@/services/DownloadService";

const printListsOverview = {
  namespaced: true,
  state: {
    ...tableState,
    search: [
      { column: "order[printedDt]", value: "desc" }
    ]
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        DownloadService.printLists.list({
          pagination: context.state.pagination,
          sorting: context.state.sorting,
          search: context.state.search
        })
          .then((json) => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch((err) => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    }
  },
};

export default printListsOverview;
