import Vue from "vue";

Vue.mixin({
    methods: {
        filename: function () {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0");
            today =
                dd +
                "-" +
                mm +
                "-" +
                today.getFullYear() +
                "T" +
                today.getHours() +
                today.getMinutes();

            return today + "-download-list.zip";
        },
    },
});
